import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext.js';

const CheckoutForm = ({ onSuccessfulCheckout, programme, onClose }) => {

  const { currentUser, userData, loadUserData } = useAuth();

  useEffect(() => {
    if (currentUser && (!userData || !userData.email)) {
      loadUserData(currentUser.uid).catch(error => {
        console.error("Erreur lors du chargement des données utilisateur", error);
      });
    }
  }, [currentUser, userData, loadUserData]);
  
  const stripe = useStripe();
  const elements = useElements();

  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setPaymentStatus("Stripe n'est pas chargé correctement.");
      return;
    }

    if (!userData || !userData.email || !userData.emailVerified) {
      setPaymentStatus("Veuillez vous reconnecter ou faire revérifier votre mail.");
      return;
    }

    setIsSubmitting(true);
    setPaymentStatus('');

    try {
      if (paymentMethod === 'card') {
        // Logique pour le paiement par carte
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod: createdPaymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
      
        if (error) {
          throw new Error(`Erreur lors de la création du PaymentMethod : ${error.message}`);
        }
      
        try {
          const response = await fetch('https://us-central1-site-web-b50d8.cloudfunctions.net/api/process_payment', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              paymentMethodId: createdPaymentMethod.id,
              programmeId: programme.id,
            }),
          });

          if (!response.ok) {
            // Tentez de parser le corps de la réponse comme JSON pour extraire plus d'informations sur l'erreur
            let errorMsg = `Erreur lors de l'appel API, statut : ${response.status} (${response.statusText})`;
            try {
              const errorBody = await response.json(); // Tente de parser le corps de la réponse
              // Supposons que l'API retourne un champ 'message' dans le JSON d'erreur
              errorMsg += `: ${errorBody.message}`;
            } catch (parseError) {
              // Si le parsing échoue, cela signifie que le corps n'est pas un JSON valide ou qu'il n'y a pas de corps
              errorMsg += `. Impossible de parser la réponse d'erreur.`;
            }
            throw new Error(errorMsg);
          }          
        
          const paymentIntentResult = await response.json();
          
          if (!paymentIntentResult.success) {
            throw new Error(`Erreur du serveur lors du traitement du paiement : ${paymentIntentResult.message}`);
          }
        
          // Si le PaymentIntent n'est pas déjà réussi, tenter de le confirmer
          if (paymentIntentResult.status !== 'succeeded') {
            const confirmResult = await stripe.confirmCardPayment(paymentIntentResult.clientSecret, {
              payment_method: {
                card: cardElement,
                billing_details: {
                  email: userData.email, 
                },
              },
            });
        
            if (confirmResult.error) {
              throw new Error(`Erreur lors de la confirmation du PaymentIntent : ${confirmResult.error.message}`);
            }

            if (confirmResult.paymentIntent && confirmResult.paymentIntent.status === 'succeeded') {
              setPaymentStatus('Paiement réussi. Votre programme est disponible dans le menu.');
              onSuccessfulCheckout();
              navigate(`/verification?payment_intent_client_secret=${paymentIntentResult.clientSecret}`);
            }
          } else {
            setPaymentStatus('Paiement déjà réussi.');
            onSuccessfulCheckout();
            navigate(`/verification?payment_intent_client_secret=${paymentIntentResult.clientSecret}`);
          }
        } catch (error) {
          console.error('Erreur lors du processus de paiement:', error);
        }
      }
      // else if (paymentMethod === 'paypal')
    } catch (error) {
      setPaymentStatus(`Erreur : ${error.message}`);
      
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="overlay-checkout"></div>
      <div className="form-checkout">
        <button onClick={onClose} className="close-button">
          <img src="/pictures/close_icon.png" alt="Fermer" />
        </button>
        <form onSubmit={handleSubmit}>
          <p>Obtenir le programme : {programme.title}</p>
          <Link to="/#about" style={{ textDecoration: 'none' }}> Que vais-je apprendre avec ce programme ?</Link> 
          <p>
            <del>{programme.price + 30} € </del>
            <span>  {programme.price} € TTC</span>
          </p>
          <div className="payment-method-selector">
            <label>
              <input
                type="radio"
                value="card"
                checked={paymentMethod === 'card'}
                onChange={() => setPaymentMethod('card')}
              />
              Carte Bancaire
            </label>
            <label>
              <input
                type="radio"
                value="paypal"
                checked={paymentMethod === 'paypal'}
                onChange={() => setPaymentMethod('paypal')}
              />
              PayPal
            </label>
          </div>
          <p></p>
          <div className="payment-details">
            {paymentMethod === 'card' && (
              <div className="card-details">
                <CardElement />
                {/* Vous pouvez personnaliser CardElement pour ajuster son style */}
              </div>
            )}
            {paymentMethod === 'paypal' && (
              <div className="paypal-details">
                {/* Implémentez votre logique d'intégration PayPal ici */}
                <p>Intégration prochaine de PayPal.</p>
              </div>
            )}
          </div>
          <p></p>
          <button type="submit" disabled={!stripe || isSubmitting} className="submit">
            {isSubmitting ? 'Traitement...' : 'Payer'}
          </button>
          {paymentStatus && <div className="payment-status">{paymentStatus}</div>}
        </form>
      </div>
    </>
  );
};

export default CheckoutForm;
