import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StripeCheckout from './StripeCheckout.js';
import { db } from './donnees/firebase.js';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { useAuth } from './AuthContext.js';

const ListeCours = () => {
  const navigate = useNavigate();

  const { currentUser, userData, loadUserData, updateUserCoursAchetes, updateTitle } = useAuth();
  const [idProgrammeItem, setIdProgrammeItem] = useState('');
  const [userConnected, setUserConnected] = useState(null);

  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedProgramme, setSelectedProgramme] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    updateTitle("Flowgraines - Programmes");
    if (currentUser) {
      loadUserData(currentUser.uid);
      setUserConnected(currentUser && userData?.emailVerified)
    }
    else
    {
      setUserConnected(null);
    }
      
  }, [currentUser, loadUserData, userData, updateTitle]);

  const handleClick = (path, programmeId) => {
    const isCoursePurchased = userData?.coursAchetes?.includes(programmeId);
    setIdProgrammeItem(programmeId);

    if (userConnected && isCoursePurchased) 
    {
      navigate(path);
    } else {
      setShowAnimation(true); // Animation si pas connecté  
      setTimeout(() => setShowAnimation(false), 400);
    }
  };

  const handlePayment = (programme) => {
    setSelectedProgramme(programme);
    setShowCheckout(true); 
  };

  const addCoursAchete = async (courseId) => {
    // Référence au document de l'utilisateur dans la collection 'utilisateurs'
    const userRef = doc(db, 'utilisateurs', currentUser.uid);
  
    try {
      // Mise à jour du document utilisateur avec le nouveau cours acheté
      await updateDoc(userRef, {
        coursAchetes: arrayUnion(courseId)
      });
      updateUserCoursAchetes(courseId);
      console.log('Cours ajouté avec succès.');

    } catch (error) {
      console.error('Erreur lors de l\'ajout du cours acheté:', error);
      alert(`Erreur lors de l'ajout: ${error.message}`);
    }
  };

  const handleClose = () => {
    setShowCheckout(false);
  };

  // prix à mettre à jour sur le serveur dans les fonctions cloud
  const programmes = [
    { 
      id: "Cours1", 
      title: "Flowgraine S'aimer soi-même", 
      description: [ "Plongez au cœur de vous-même à travers ce programme, et découvrez comment s'aimer soi-même plus profondément. Comment ouvrir la porte à une vie plus authentique et être en harmonie avec nos aspirations profondes ?", "Accès à un cours et des exercices (une bonne vingtaine de pages et 15 minutes de vidéo)", "Un groupe de partages et de contenus supplémentaires hebdomadaires (1 à 2 fois par semaine). Des échanges pour s'améliorer et surtout se dépouiller de ces croyances qui nous ont fait perdre notre authenticité, notre joie, ou bien notre spontanéité. Alors préparez-vous à des changements et des retrouvailles intérieures !" ], 
      path: "/formationSaimer", 
      price: 13
    },
    // { 
    //   id: "Cours2", 
    //   title: "Flowgraine Écouter la vie en soi", 
    //   description: [ "Prochain cours. Suivez l'actu !" ], 
    //   path: "", 
    //   price: 35
    // }
  ];

  return (
    <div className="content">
      <div className='listeCours_p'>
        {currentUser ? (
            <p>
              Bienvenue <span style={{ color: '#4a90e2' }}>{userData?.nom ? userData.nom : ''}</span>, découvrez les cours Flowgraines et faites fleurir votre potentiel ! Ils vous guideront pour dépasser vos croyances limitantes, vous ouvrir à l'amour et maîtriser votre monde intérieur. Prêt à faire éclore votre véritable potentiel ?<br />
            </p>
            ) : (
            <p>
              Bienvenue, ici on appelle nos programmes, les flowgraines. Des cours animés, des graines, qui nous guident vers le flow ! <br />
            </p>
        )}
      
        {currentUser && !userConnected && (
          <p>Veuillez vérifier votre adresse e-mail</p>
        )}
        
        {!currentUser&& <p className="fonce">Accédez à ces cours passionnants en vous connectant !</p>}
      </div>

      <div className="programmes-list">

        <div className="programme-item" onClick={() => navigate('/formationCroissance')}>
          <h1>Flowgraine Notre croissance personnelle</h1>
          <p>La croissance personnelle est un défi quotidien qui nécessite de la patience et de la persévérance. Développons un état d'esprit non pas juste de "gagnant" car la vie nous montrera toujours qu'il faut savoir être résilient, mais un état d'esprit d'engagement envers soi-même, de résilience, et d'ouverture. <br />
          <em style={{ fontSize: '0.9em' }}>Disponible pour tous.</em>
          </p>
          <div style={{ color: 'rgba(255, 255, 255, 0.9)', backgroundColor: 'rgba(128, 0, 128, 0.1)' }}>
            Accès au cours
          </div>
        </div>

        {programmes.map((programme) => (
          
          <div key={programme.id} 
          className={`programme-item ${idProgrammeItem === programme.id && showAnimation ? "animate" : ""}`} 
          onClick={() => handleClick(programme.path, programme.id)}>
            <h1>{programme.title}</h1>
            {programme.description.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
            {userConnected && ( 
              userData?.coursAchetes?.includes(programme.id) ? (
                <div style={{ color: 'rgba(255, 255, 255, 0.9)', backgroundColor: 'rgba(128, 0, 128, 0.1)' }}>
                  Accès au cours
                </div>
              ) : (
                <button className="get-button" onClick={(e) => {
                  e.stopPropagation();
                  handlePayment(programme); 
                }}>
                  Obtenir le cours 
                </button>
              )
            )}
          </div>
        ))}

        <div className="programme-item" onClick={() => navigate('/listeCours')}>
          <h1>Flowgraine Écouter la vie en soi</h1>
          <p>
            Prochain cours. Suivez l'actu ! <br /><a href="https://www.instagram.com/oretlien_art/" rel="noreferrer noopener" target="_blank">🎨 Suivez-moi sur Instagram !</a><br />
          </p>
          <div style={{ color: 'rgba(255, 255, 255, 0.9)', backgroundColor: 'rgba(128, 0, 128, 0.1)' }}>

          </div>
        </div>

        <>
        {showCheckout && currentUser ? (
          <StripeCheckout
            onSuccessfulCheckout={() => {
              addCoursAchete(selectedProgramme.id);
              setShowCheckout(false);
            }}
            programme={selectedProgramme}
            onClose={handleClose}
          />
        ) : (
            <p></p>
        )}
        </> 
      </div>

    </div>  
  );
};

export default ListeCours;
